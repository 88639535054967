class CategorySelect {
    constructor() {
        const selects = [...document.querySelectorAll(".js-category-select")];

        selects.forEach(function (select) {
            select.addEventListener("change", function (e) {
                const searchParams = new URLSearchParams(
                    window.location.search
                );
                const category = e.target.value;
                const name = e.target.name;
                if (category == "0") {
                    searchParams.delete(name);
                } else {
                    searchParams.set(name, category);
                }

                window.location.search = searchParams.toString();
            });
        });
    }
}

export default CategorySelect;
