class Toc {
    constructor() {
        this.build();

        this.mobileToggle();
    }

    build() {
        var $result = $(".js-toc");

        var count = 0;

        var $this = this;

        $(".js-toc-content h1,.js-toc-content h2").each(function () {
            $(this).attr("id", "toc_" + count);

            var $li =
                '<li><a href="#toc_' +
                count +
                '" class="">' +
                $(this).text() +
                $this.svg() +
                "</a></li>";

            $result.append($li);

            count++;
        });

        $result = $result.parents("ol:last");
    }

    mobileToggle() {
        $(".toc-mobile-button").click(function () {
            $(this).next().toggleClass("active");
        });
    }

    svg() {
        return '<svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Arrow icon</title><g id="Contact" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Contact_desktop" transform="translate(-231.000000, -171.000000)" fill="#231F20"><g id="Menu" transform="translate(102.000000, 160.000000)"><polygon id="Arrow-icon" points="140.119718 11 138.331457 12.8135309 144.153106 18.7174463 129 18.7174463 129 21.2825537 144.159429 21.2825537 138.331457 27.1848659 140.118453 29 149 20.0054509"></polygon></g></g></g></svg>';
    }
}

export default Toc;
