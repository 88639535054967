window.td_menu = function () {
    return {
        showMenu: false,
        openMenus: [],

        toggleMenu(menuItemID, parentID) {
            if (!this.isMenuOpen(menuItemID)) {
                if (parentID == 0) {
                    this.closeAllMenus();
                }

                this.openMenus = this.openMenus.concat({
                    id: menuItemID,
                    parent: parentID,
                });

                return;
            }

            // Close the current menu item and it's children
            this.openMenus = this.openMenus.filter((openMenuItem) => {
                return (
                    openMenuItem.parent != menuItemID &&
                    openMenuItem.id != menuItemID
                );
            });
        },

        closeAllMenus() {
            this.openMenus = [];
        },

        isMenuOpen(menuID) {
            return this.openMenus.some(
                (openMenuItem) => openMenuItem.id === menuID
            );
        },

        onEscape(e) {
            this.closeAllMenus();

            let topLevelButton = e.currentTarget.querySelector("button");
            if (topLevelButton) {
                topLevelButton.focus();
            }
        },

        onClickAway({ target }) {
            if (
                !target.classList.contains("js-has-dropdown") &&
                !target.classList.contains("js-is-dropdown")
            ) {
                this.closeAllMenus();
            }
        },
    };
};
