class SliderButtons {
    constructor() {
        $("body").on("click", ".js-slider-buttons button", function () {
            if ($(this).attr("data-direction") == "next") {
                $(this)
                    .closest(".js-slider-container")
                    .find(".slick-next")
                    .click();
            } else {
                $(this)
                    .closest(".js-slider-container")
                    .find(".slick-prev")
                    .click();
            }
        });

        this.createButtonObserver();
    }
    /**
     * Slick slider adds an "aria-disabled" attribute but not a "disabled"
     * attribute to the prev/next buttons. Using a mutation observer, we can
     * listen to changes to the buttons to add the correct attributes.
     */
    createButtonObserver() {
        const ariaDisabledAttr = "aria-disabled";
        const disabledAttr = "disabled";
        const observer = new MutationObserver(function (mutationsList) {
            for (const mutation of mutationsList) {
                const el = mutation.target;
                const value = el.getAttribute(ariaDisabledAttr);
                if (value === "true") {
                    el.setAttribute(disabledAttr, "");
                } else {
                    el.removeAttribute(disabledAttr);
                }
            }
        });

        [...document.querySelectorAll(".js-slider-buttons button")].forEach(
            (targetNode) => {
                observer.observe(targetNode, {
                    attributeFilter: [ariaDisabledAttr],
                });
            }
        );
    }
}

export default SliderButtons;
